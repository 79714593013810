<template>
  <div class="total">
    <div class="configure">
      <div style="margin: 20px;">
        <as-list
          :ajaxMethods="{ list: 'GetNoticelist' }"
          :listQueryProp="listQueryStudent"
        >
          <!-- <el-table-column prop="dangan" label="排序"></el-table-column> -->
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <!-- <el-table-column prop="weight_int" label="排序"></el-table-column> -->

          <el-table-column prop="notice_title" label="消息内容">
            <template slot-scope="scope">
              <span
                v-if="scope.row.notice_title"
                @click="editHandle('11', scope.row)"
                class="cursor"
              >
                {{ scope.row.notice_title }}</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="Username" label="发送人"></el-table-column>
          <el-table-column label="发送日期">
            <template slot-scope="scope">
              <span v-if="scope.row.addtime" class="cursor">
                {{ scope.row.addtime.split("T")[0] }}</span
              >
            </template>
          </el-table-column>
        </as-list>
      </div>
    </div>
    <AddFile
      :Visible="editVisible"
      :message="message"
      :student_id="student_id"
      @closepop="closeEditPop"
    ></AddFile>
  </div>
</template>
<script>
import AddFile from "./components/addFile.vue";
export default {
  components: {
    AddFile,
  },
  data() {
    return {
      message: {},
      student_id: 0,
      editVisible: false,
      listQueryStudent: {
        KeyWord: "",
        notice_type: "",
        PageIndex: 1,
        PageSize: 10,
      },
    };
  },
  created() {},
  methods: {
    editHandle(val, row) {
      this.editVisible = true;
      this.message = row;
      this.student_id = row.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 45px;
  height: 45px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.text:hover {
  background-color: #f0f0f0;
  color: #333333;
}

.textBox:active {
  opacity: 0.5;
}

.nameStyle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
