<template>
  <el-dialog
    :title="message.title"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="80%"
  >
    <div style="overflow: scroll">
      <div class="flex justify-content-center">
        <span style="font-size: 24px">{{ datalist.notice_title }}</span>
      </div>
      <div class="flex justify-content-around margin-top">
        <span>{{ datalist.Username }}</span
        ><span v-if="datalist.addtime">{{
          datalist.addtime.split("T")[0]
        }}</span>
      </div>
      <div
        style="height: 700px"
        class="margin-top"
        v-html="datalist.notice_content"
      ></div>
      <div style="font-size: 16px">
        <div style="font-size: 16px">
          <i style="font-size: 16px" class="el-icon-folder-opened"></i>附件:
        </div>
        <div>
          <ul class="margin-top-sm">
            <li
              style="font-size: 16px"
              v-for="(item, index) in datalist.enclosure"
              :key="index"
            >
              <i style="font-size: 16px" class="el-icon-tickets"></i>
              <a style="font-size: 16px" class="margin-left-sm" :href="item.src | previewImage">{{
                item.Name
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-content-flexEnd margin-top">
        <el-button type="primary" @click="close">关闭</el-button>
        <!-- <el-button class="myClient-left-30" @click="close">取消</el-button> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    student_id: {
      type: Number,
      default: 0,
    },
    Visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      datalist: [],
      dialogVisible: false,
      noticeId: this.student_id,
      listQuery: {
        FolderName: "",
        ParentId: 0,
        FolderType: "",
      },
    };
  },
  watch: {
    student_id: {
      deep: true,
      handler(val) {
        this.noticeId = val;
        this.getNotice();
      },
    },

    Visible(val) {
      this.listQuery = {
        FolderName: "",
        ParentId: 0,
        FolderType: "",
      };
      console.log(this.message);
      this.dialogVisible = val;
      this.listQuery.FolderType = this.message.type;
      if (this.message.num == 2) {
        this.listQuery.FolderName = this.message.detail.FolderName;
        this.listQuery.ParentId = this.message.detail.ParentId;
        this.listQuery.id = this.message.detail.id;
        this.listQuery.FolderType = this.message.detail.FolderType;
      } else if (this.message.num == 1) {
        let list = this.message.detail;
        this.listQuery.ParentId = list.id;
      }
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    //获取通知
    getNotice() {
      this.API.Get_Notice({ id: this.noticeId }).then((res) => {
        this.datalist = res;
        this.datalist.enclosure = JSON.parse(this.datalist.enclosure);
      });
    },

    close() {
      this.$emit("closepop");
    },
    AddEditFolde() {
      this.API.cloudDiskEditFolder(this.listQuery).then((res) => {
        if (res.code == 200) {
          this.$message.success("文件夹操作成功");
          this.close();
        }
      });
    },
  },
};
</script>

<style>
</style>
